import './styles/rebrand.css';
import React, { useEffect, useState } from 'react';
import { 
    Container,
    Modal,
    Button,
    Spinner,
    Row
} from 'react-bootstrap';
import { useWorkspaceDataStore } from './data/WorkspaceDataStoreProvider';
import { 
    Plus} from './components/Icons';
import EditVariable from './components/EditVariable';
import { updateVariable } from './functions/variables';
import { useTopDataStore } from './data/TopDataStoreProvider';
import { deleteVarset } from './functions/variables';
import VarsetCard from './components/VarsetCard';


const defaultVariable = {
    id: "",
    variant: "Default",
    placeholder: "",
    example: "",
    updated_at: "",
    version: 1,
    select: false,
    options: [],
}

const defaultVarset = {
    id: "",
    name: "Untitled",
    description: "",
    form_section: "",
    multiple: false,
    update_at: "",
    version: 1, 
    variables: [defaultVariable],
}

export default function VariablesList() {
    const { workspaceData, setWorkspaceData } = useWorkspaceDataStore();
    const { topData } = useTopDataStore();

    const [sortedVarSets, setSortedVarSets] = useState([]);
    const [expandedIndices, setExpandedIndices] = useState([]);
    const [expandedVarIndices, setExpandedVarIndices] = useState({});
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentVarset, setCurrentVarset] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [varsetToDelete, setVarsetToDelete] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        const updatedSort = [...workspaceData.varSets].sort((a, b) => a.name.localeCompare(b.name));
        setSortedVarSets(updatedSort);
        setWorkspaceData({
            ...workspaceData,
            sortedVarSets: updatedSort,
        });
    }, [workspaceData.varSets]); // updates when variables are added or removed from varSets.



    const addVariable = (e, workspaceData, setWorkspaceData) => {
        e.preventDefault();
    
        // Create a new default variable
        const newVarset = { ...defaultVarset, id: "" };
    
        // Add the new variable to the workspaceData
        const updatedVarSets = [...workspaceData.varSets, newVarset];
        setWorkspaceData({
            ...workspaceData,
            varSets: updatedVarSets,
        });
    
        // Open the edit form for the new variable
        setCurrentVarset(newVarset);
        setShowEditModal(true);
    };
    
    const handleCancel = () => {
        // Remove the new variable if it was not saved
        if (currentVarset && currentVarset.id === "") {
            const updatedVarSets = sortedVarSets.filter(vs => vs.id !== "");
            setSortedVarSets(updatedVarSets);
            setWorkspaceData({
                ...workspaceData,
                varSets: updatedVarSets,
            });
        }
        setShowEditModal(false);
    };
    
    const confirmDelete = async () => {
        if (!varsetToDelete) return;

        setIsDeleting(true);
        try {
            const response = await deleteVarset(varsetToDelete.id, varsetToDelete.version, topData);
            if (response.status === 200) {
                const updatedVarSets = workspaceData.varSets.filter(vs => vs.id !== varsetToDelete.id);
                setWorkspaceData({
                    ...workspaceData,
                    varSets: updatedVarSets,
                });
                setShowDeleteModal(false);
            } else {
                alert(`Unable to delete variable ${varsetToDelete.id}`);
            }
        } catch (error) {
            alert(`Unable to delete variable ${varsetToDelete.id}`);
        } finally {
            setIsDeleting(false);
            setVarsetToDelete(null);
        }
    };

    const deleteVariable = (e, id, version) => {
        e.preventDefault();
        setVarsetToDelete({ id, version });
        setShowDeleteModal(true);
    };

    const toggleExpand = (id) => {
        setExpandedIndices((prevIndices) =>
            prevIndices.includes(id)
                ? prevIndices.filter((i) => i !== id)
                : [...prevIndices, id]
        );

        // Reset the expanded state of child variables when the parent is collapsed
        setExpandedVarIndices((prevState) => {
            if (expandedIndices.includes(id)) {
                const newState = { ...prevState };
                delete newState[id];
                return newState;
            }
            return prevState;
        });
    };

    const toggleVarExpand = (setId, varId) => {
        setExpandedVarIndices((prevState) => ({
            ...prevState,
            [setId]: prevState[setId]
                ? prevState[setId].includes(varId)
                    ? prevState[setId].filter((i) => i !== varId)
                    : [...prevState[setId], varId]
                : [varId],
        }));
    };

    const handleEditClick = (varset) => {
        setCurrentVarset(varset);
        setShowEditModal(true);
    };

    const handleSave = async (formVarset) => {
        const newVar = (!formVarset.id) ? true : false;
        try {
            let updatedVarSets;
            
            const res = await updateVariable(formVarset, topData);
            if (res.status === 401) {
                const data = await res.json();
                alert(data.message || "Error updating variable.");
              } else if (res.status === 200) {
                const data = await res.json();
                const vset = data.vset;
                if (newVar) {
                    updatedVarSets = [...sortedVarSets, vset].sort((a, b) => a.name.localeCompare(b.name));
                } else {
                    updatedVarSets = sortedVarSets.map((vs) =>
                        vs.id === vset.id ? vset : vs
                    );
                };
              }            

            setSortedVarSets(updatedVarSets);
            setWorkspaceData({
                ...workspaceData,
                varSets: updatedVarSets,
            });
        
        } catch (error) {
            alert("Error updating variable:", error);
        }
    };
    const formSections = ["Study Information", "Objectives", "Study Design", "Patient Population", "Variables"];
    return (
        <Container>
            <div className="docListItem" id="newTemplate">
                <a href="#id" onClick={(e) => addVariable(e, workspaceData, setWorkspaceData)}>
                    <Plus />Create New Variable
                </a>
            </div>
            {formSections.map((f, index) => (
                <Row key={f}>
                    <h3 className="subheading mt-3 mb-4">{f}</h3>
                    <div className="d-flex flex-wrap gap-3 align-items-start">
                        {sortedVarSets
                            .filter(i => i.name !== "Untitled")
                            .filter(i => i.form_section === f)
                            .map((i) => (
                                <VarsetCard
                                    key={i.id}
                                    varset={i}
                                    index={i.id}
                                    expandedIndices={expandedIndices}
                                    expandedVarIndices={expandedVarIndices}
                                    toggleExpand={toggleExpand}
                                    toggleVarExpand={toggleVarExpand}
                                    deleteVariable={deleteVariable}
                                    handleEditClick={handleEditClick}
                                />
                            ))}
                        {sortedVarSets
                            .filter(i => i.name !== "Untitled")
                            .filter(i => i.form_section === f).length === 0 && (
                            <div className="text-mute">(No variables)</div>
                        )}
                    </div>
                </Row>
            ))}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this variable set? This action cannot be undone.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)} disabled={isDeleting}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDelete} disabled={isDeleting}>
                        {isDeleting ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Delete'}
                    </Button>
                </Modal.Footer>
            </Modal>
            <EditVariable
                show={showEditModal}
                handleClose={() => setShowEditModal(false)}
                varset={currentVarset}
                onSave={handleSave}
                onCancel={handleCancel}
            />
        </Container>
    );
}
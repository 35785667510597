import './App.css';
import { useEffect, useRef } from "react";
import { Container, Row } from 'react-bootstrap';
import { useTopDataStore, initialState } from "./data/TopDataStoreProvider";
import { useWorkspaceDataStore, refreshState } from './data/WorkspaceDataStoreProvider';
import { useLoadDataStore } from './data/LoadDataStoreProvider';
import NotificationsDataStoreProvider from "./data/NotificationsDataStoreProvider";

import TemplateOutletID from './asc.template.ID';
import CreateDocOutlet from "./asc.doc.new";
import Dashboard from "./asc.home";
import Header from "./components/Header";
import DocumentList from "./asc.docs.list";
import DocumentOutlet from './asc.doc.ID';
import TemplatesList from "./asc.templates.list";
import VariableOutletId from "./asc.variables";
import Profile from "./asc.profile";
import StudyList from './asc.studies.list';
import StudyOutletID from './asc.study.ID';

import { statusGET } from './functions/api-calls';
import { fetchDataForWorkspace } from './functions/workspace';
import LoadingModal from './components/LoadingModal';
import { logout } from './functions/workspace';
import { WorkflowComingSoon } from './components/WorkflowComingSoon';

function Content() {
  const { topData } = useTopDataStore();
  switch (topData.page) {
    case "variables":
      return (<VariableOutletId />);
    case "studyList":
      return (<StudyList />);
      // return (<WorkflowComingSoon />);
    case "studyText":
    case "studyId":
        return (<StudyOutletID />);
        // return (<WorkflowComingSoon />);
    case "documentPageDisambiguate":
    case "edit":
    case "synopsis":
    case "studyInformation":
    case "objectives":
    case 'studyDesign':
    case 'patientPopulation':
    case '_variables':  // TO DO: Fix the repeated use of the word "_variables" as a data category --> "characteristics"
      return (<DocumentOutlet />);
      // return (<WorkflowComingSoon />);
    case "documentList":
      return (<DocumentList />);
      // return (<WorkflowComingSoon />);
    // Switch for all pages for template management or in the Template Outlet
    case "templates":
      return (<TemplatesList />);
    case "createDocOutlet":
      return (<CreateDocOutlet />);
      // return (<WorkflowComingSoon />);
    case "templateSetup":
    case "templateText":
    case "templateSaveChoices":
    case "templateTitleLayout":
    case "templateTitleEdit": 
      return (<TemplateOutletID />);  
    case "profile":
      return (<Profile />);
    // Switch for dashboard and profile pages
    case "welcome":
    default:
      return (
        <NotificationsDataStoreProvider>
          <Dashboard />
        </NotificationsDataStoreProvider>
      );
  }
}

export default function Wrapper() {
  const dupCheckRef = useRef(true);

  const { topData, setTopData } = useTopDataStore();
  const { workspaceData, setWorkspaceData } = useWorkspaceDataStore();
  const { loadData, setLoadData } = useLoadDataStore();

  useEffect(() => {
    fetchDataForWorkspace(topData, workspaceData, setWorkspaceData, setLoadData, ["V", "S", "T"]);
  }, []);

  useEffect(() => {
    if (dupCheckRef.current) {
      let interval = null;
      interval = setInterval(() => {
        if (topData.token) {
          statusGET(topData, setTopData, interval);
        }

        else{
          alert("Session expired.");
          logout(setWorkspaceData, refreshState, setTopData, initialState);
        }
      }, 1800000);  // Runs once every half hour

      return () => {
        clearInterval(interval);
      };
    }
  }, [topData.page]);

  return (
    <Container fluid>
      {loadData.isLoading && (
        <LoadingModal progress={loadData.loadingProgress} />
      )}
      <Row>
        {!loadData.isLoading && <Header />}
      </Row>
      {!loadData.isLoading && <Content />}
      </Container>
  );
}
